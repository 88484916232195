// import BaseSelect from '@/components/common/base-select/base-select.vue'
import selectAccount from '../components/select-account-dialog.vue'
import replyUpload from '../components/reply-upload.vue'
import { getDictLists } from '@/filters/fromDict'
import { formatDate } from '@/utils/auth/common'

// 加锁展示数据
export const formInfoConfig = () => {
  const span = 8
  return [
    // {
    //   label: 'EAS标识码',
    //   prop: 'contractId',
    //   span
    // },
    {
      label: '供应商',
      prop: 'gysName',
      span
    },
    {
      label: '项目公司',
      prop: 'xmgsName',
      span
    },
    {
      label: '合同编号',
      prop: 'contractCode',
      span
    },
    {
      label: '合同名称',
      prop: 'contractName',
      span
    },
    {
      label: '合同类别',
      prop: 'contractType',
      span
    },
    {
      label: '合同金额',
      prop: 'contractAmount',
      span
    }
  ]
}
// 加锁选择数据
export const lockForm = (content) => {
  return [
    {
      label: '收款人名称:',
      prop: 'account',
      attrs: {
        enterpriseId: content.formData.gysId,
        placeholder: '请选择账户信息',
        disabled: true,
        accountId: content.formData.accountId
      },
      span: 8,
      labelWidth: '90px',
      tag: selectAccount,
      on: {
        selectAccount (data) {
          content.selectAccount(data)
        }
      }
    },
    {
      label: '收款人开户银行:',
      prop: 'bankName',
      span: 8,
      attrs: {
        placeholder: '请选择账户信息',
        disabled: true
      },
      labelWidth: '120px'
    },
    {
      label: '收款人银行账号:',
      prop: 'accountNo',
      span: 8,
      attrs: {
        placeholder: '请选择账户信息',
        disabled: true
      },
      labelWidth: '120px'
    },
    {
      label: '回 函 文 件:',
      prop: 'fileName',
      tag: replyUpload,
      labelWidth: '90px',
      span: 10,
      attrs: {
        placeholder: '请上传回函文件',
        fileId: content.formData.fileId,
        width: '200px'
      },

      on: {
        uploadFile: (data) => {
          content.uploadFile(data)
        },
        delFile: (data) => {
          content.delFile(data)
        },
        preview: () => {
          content.preview()
        }
      }
    }
  ]
}
// 履历数据
export const resumeData = (content) => {
  return [
    {
      label: '序号',
      prop: 'code'
    },
    {
      label: '处理人',
      prop: 'addUserName'
    },
    {
      label: '处理时间',
      prop: 'addTime',
      formatter: row => {
        return formatDate(row.addTime, 'YY/MM/DD hh:mm:ss')
      }
    },
    {
      label: '处理结果',
      prop: 'lockResult'
    }

  ]
}

// 选择账户信息
export const selectAccountColumns = () => {
  return [
    {
      label: '',
      prop: 'radio',
      width: '80px'
    },
    {
      label: '户名',
      prop: 'account',
      align: 'left'
    },
    {
      label: '开户行',
      prop: 'bankName',
      align: 'left'
    },
    {
      label: '账号',
      prop: 'accountNo',
      align: 'left'
    },
    {
      label: '账户类型',
      prop: 'accountType',
      formatter: row => {
        const [obj] = getDictLists('SUPPLIER_BANK_TYPE').filter(item => item.dictId === row.accountType.toString())
        return obj ? obj.dictName : '-'
      }
    }
  ]
}
// 新增账户信息
export const addAccountColumns = () => {
  return [
    {
      label: '户名',
      prop: 'account'
    },
    {
      label: '开户行',
      prop: 'bankName'
    },
    {
      label: '账号',
      prop: 'accountNo'
    },
    {
      label: '账户类型',
      prop: 'accountType'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
